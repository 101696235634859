import {styled} from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {useState} from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


export const CientsOrders = ({clientEmail, ordersData}) => {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid item xs={12}>
            {
                ordersData.map((order, index) => {
                    return (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon/>}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Typography>{order.full_number ? order.full_number : "W trakcie weryfikacji"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <Typography>Status :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.stage}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Typography>Urządzenie :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.device_name}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Typography>Model :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.device_code}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Typography>Numer seryjny :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.serial_number}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Typography>Data zakupu :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.purchase_date}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Typography>Data akceptacji :</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{order.acceptance_date}</Typography>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Grid>
    )
}