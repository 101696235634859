import {
    Alert,
    Button, Snackbar,
    ThemeProvider,
    Typography
} from "@mui/material";
import {theme} from "./theme";
import {Item} from "./custom/item";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Logo from "./static/new_logo.png";

import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import TextField from "@mui/material/TextField";
import axios from "axios";
import {API} from "./constants";
import {useNavigate} from "react-router-dom";

const RegisterForm = () => {
    let navigate = useNavigate();
    const {data} = useParams();
    const [registerData, setRegisterData] = useState({})
    const [passwordError, setPasswordError] = useState(false);
    const [passwordCharactersIncorrect, setPasswordCharactersIncorrect] = useState(true);
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [severityMessage, setSeverityMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [key, setKey] = useState(0);
    const registerUser = () => {
        axios.post(API.USERS, registerData).then((response) => {
            if (response.status === 201) {
                // navigate("/");
                setSeverityMessage('success');
                setMessage('Konto zostało utworzone.');
                setIsOpenSuccess(true);
            }else {
                setSeverityMessage('error');
                setMessage('Błąd podczas tworzenia konta.');
                setIsOpenSuccess(true);
            }
        })
    }
    const checkData = async (uuid, email) => {
        try {
            axios.get(`${API.VALIDATION}${uuid}/validate/?email=${email}`, {validateStatus: false}).then(response => {
                if (response.status === 404) {
                    setRegisterData({});
                    navigate("/");
                }
            })
        } catch (err) {
            navigate("/");
        }

    }

    const validatePassword = () => {
        const password1 = registerData.password;
        const password2 = registerData.password2;
        password1 && password1.length < 8 ? setPasswordCharactersIncorrect(true) : setPasswordCharactersIncorrect(false)
        if (password1 && password2) {
            password1 !== password2 ? setPasswordError(true) : setPasswordError(false)
        }
        if (!password2) {
            setPasswordError(false)
        }

    }

    const handleCloseSuccess = () => {
        setIsOpenSuccess(false);
        navigate("/");
    }

    useEffect(() => {
        validatePassword()
    }, [registerData.password, registerData.password2]);

    useEffect(() => {
        const uriDecoded = atob(decodeURIComponent(data));

        const emailRegex = /email=([^;]+)/;
        const uuidRegex = /uuid=([a-fA-F0-9-]{36})/;

        const emailMatch = uriDecoded.match(emailRegex);
        const uuidMatch = uriDecoded.match(uuidRegex);

        const email = emailMatch ? emailMatch[1] : null;
        const uuid = uuidMatch ? uuidMatch[1] : null;
        checkData(uuid, email).then(() => {
            setRegisterData({order_uuid: uuid, email: email, username: email});
            setKey(key + 1);
        })

    }, [data])

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <header className="App-header">
                    <div>
                        <Item elevation={0}>
                            <Box sx={{padding: '40px', width: "1000px", maxHeight: "1400px", minHeight: "1000px"}}>
                                <Box sx={{paddingBottom: "20px"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div>
                                                <img src={Logo} style={{width: '20vh'}} alt="logo"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div key={key}>
                                                <Typography variant="h6">Rejestracja użytkownika</Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Login"
                                                            name="email"
                                                            type="login"
                                                            disabled
                                                            defaultValue={registerData.email}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={passwordError || passwordCharactersIncorrect}
                                                            onChange={(e) => setRegisterData({
                                                                ...registerData,
                                                                password: e.target.value
                                                            })}
                                                            label="Hasło"
                                                            name="password"
                                                            type="password"
                                                            fullWidth
                                                            helperText={passwordCharactersIncorrect ? "Hasło jest za krótkie" : passwordError ? "Hasła się różnią" : "Hasło powinno zawierać minimum 8 znaków"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={(e) => setRegisterData({
                                                                ...registerData,
                                                                password2: e.target.value
                                                            })}
                                                            error={passwordError}
                                                            label="Powtórz hasło"
                                                            name="password2"
                                                            type="password"
                                                            fullWidth
                                                            helperText={passwordError ? "Hasła się różnią" : ""}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button onClick={() => registerUser()}>Zarejestruj</Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button onClick={() => {
                                                        }}>Anuluj</Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Item>
                        <Snackbar open={isOpenSuccess} autoHideDuration={2000} onClose={handleCloseSuccess}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert onClose={handleCloseSuccess} severity={severityMessage} sx={{width: '100%'}}>
                            {message}
                        </Alert>
                    </Snackbar>
                    </div>
                </header>
            </div>
        </ThemeProvider>
    )
}
export default RegisterForm;