import "dayjs/locale/pl";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Form from "./form";
import RegisterForm from "./registerForm";

require("./global");

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Form/>}/>
                <Route path="/register/:data" element={<RegisterForm/>}/>
            </Routes>
        </Router>
    )

}


export default App;
