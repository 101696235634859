import {Button, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export const LoginPage = ({open, handleClose, login}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
    };
    const [password, setPassword] = useState(null);
    const [email, setEmail] = useState(null);

    const internalLogin = () => {
        const username = email;
        const passwd = password
        setEmail(null);
        setPassword(null);
        login(username, passwd);
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Logowanie
                </Typography>
                <Typography id="modal-modal-description" sx={{mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <TextField
                                onChange={(e) => setEmail(e.target.value)}
                                label="Email"
                                name="email"
                                type="login"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(e) => setPassword(e.target.value)}
                                label="Hasło"
                                name="password"
                                type="password"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => internalLogin()}>Zaloguj</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => handleClose()}>Anuluj</Button>
                        </Grid>
                    </Grid>


                </Typography>
            </Box>
        </Modal>
    )
}